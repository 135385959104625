import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { createAction } from '../../../utils';
import {
  BannerErrorFields,
  BannerLocation,
  BannerType,
  MPLinkType,
  LandingPageType,
  TargetCustomerType,
  SelectCountryCodeType,
  TargetRewardType,
} from '../../../models/HomeBannersModel';
import { useFormContext } from 'react-hook-form';
import CreateBannerContent from '../../../components/banners/CreateBannerContent';
import { LanguageConfig } from '../../../config/CustomEnums';

const CreateBannerStepOne = ({
  banner,
  languages,
  countryCodeList,
  goodieBagList,
  attributeTagList,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, trigger, setError, clearErrors, setFocus } =
    useFormContext();

  const getLanguageTab = (language) => {
    return [
      <CreateBannerContent
        language={language}
        countryCodeList={countryCodeList}
        goodieBagList={goodieBagList}
        attributeTagList={attributeTagList}
      />,
    ];
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('countryCode/getAllList')({ pageSize: 1000 }));
    dispatch({ type: 'attributeTag/getAllList' });
    dispatch({
      type: 'goodieBags/getAllList',
      payload: { pageSize: 50, isSimplify: true, status: true },
    });

    return () => {
      dispatch({ type: 'attributeTag/clearData' });
      dispatch({ type: 'goodieBags/clearData' });
    };
  }, []);

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const manualyUrlIsValid = (value) => {
    const validUrlReg =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const result = validUrlReg.test(value);
    return result;
  };

  const save = async (isPublished) => {
    clearErrors();
    let isValid = await trigger(['order'], {
      shouldFocus: true,
    });

    //`coverPhotos.en.0`,
    console.log('@@53: ', getValues());
    const [
      landingPageType,
      manualInputUrl,
      bannerType,
      wechatUrl,
      mpLink,
      linkToMPAppId,
      linkToMPPath,
      publishLocation,
      coverPhotos,
      mobilePhotos,
      displayHorizontalForMyWalletEmpty,
      coverPhotosForVertical,
      tabletPhotosForVertical,
      mobilePhotosForVertical,
      displayVerticalForMyWalletEmpty,
      targetCustomers,
      selectCountryCodeType,
      countryCodes,
      targetReward,
      goodieBags,
      attributeTags,
    ] = getValues([
      'landingPageType',
      'manualInputUrl',
      'bannerType',
      'wechatUrl',
      'mpLink',
      'linkToMPAppId',
      'linkToMPPath',
      'publishLocation',
      'coverPhotos',
      'mobilePhotos',
      'displayHorizontalForMyWalletEmpty',
      'coverPhotosForVertical',
      'tabletPhotosForVertical',
      'mobilePhotosForVertical',
      'displayVerticalForMyWalletEmpty',
      'targetCustomers',
      'selectCountryCodeType',
      'countryCodes',
      'targetReward',
      'goodieBags',
      'attributeTags',
    ]);
    console.log('@83', isValid, getValues());

    if (
      [BannerType.HOME_PAGE.value, BannerType.MY_WALLET.value].includes(
        bannerType,
      )
    ) {
      let exsitsHorizontalImages = false;
      let exsitsVerticalImages = false;
      if (
        (publishLocation?.includes(BannerLocation.HOME_PAGE) &&
          coverPhotos?.en?.[0]) ||
        mobilePhotos?.en?.[0]
      ) {
        exsitsHorizontalImages = true;
      }
      if (
        (publishLocation?.includes(BannerLocation.HOME_PAGE) &&
          (coverPhotosForVertical?.en?.[0] ||
            tabletPhotosForVertical?.en?.[0])) ||
        mobilePhotosForVertical?.en?.[0]
      ) {
        exsitsVerticalImages = true;
      }

      if (
        (exsitsHorizontalImages ||
          !exsitsVerticalImages ||
          (displayHorizontalForMyWalletEmpty &&
            [BannerType.HOME_PAGE.value, BannerType.MY_WALLET.value].includes(
              bannerType,
            ))) &&
        publishLocation?.includes(BannerLocation.HOME_PAGE) &&
        !coverPhotos?.en?.[0]
      ) {
        isValid = false;
        setError(
          `coverPhotos.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.bannerImage.message,
          },
          { shouldFocus: true },
        );
      }
      if (
        (exsitsHorizontalImages ||
          !exsitsVerticalImages ||
          (displayHorizontalForMyWalletEmpty &&
            [BannerType.HOME_PAGE.value, BannerType.MY_WALLET.value].includes(
              bannerType,
            ))) &&
        !mobilePhotos?.en?.[0]
      ) {
        isValid = false;
        setError(
          `mobilePhotos.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.mobilePhotoForVertical.message,
          },
          { shouldFocus: true },
        );
      }

      if (
        (!exsitsHorizontalImages ||
          exsitsVerticalImages ||
          (displayVerticalForMyWalletEmpty &&
            [BannerType.HOME_PAGE.value, BannerType.MY_WALLET.value].includes(
              bannerType,
            ))) &&
        publishLocation?.includes(BannerLocation.HOME_PAGE) &&
        !coverPhotosForVertical?.en?.[0]
      ) {
        isValid = false;
        setError(
          `coverPhotosForVertical.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.coverPhotoForVertical.message,
          },
          { shouldFocus: true },
        );
      }
      if (
        (!exsitsHorizontalImages ||
          exsitsVerticalImages ||
          (displayVerticalForMyWalletEmpty &&
            [BannerType.HOME_PAGE.value, BannerType.MY_WALLET.value].includes(
              bannerType,
            ))) &&
        publishLocation?.includes(BannerLocation.HOME_PAGE) &&
        !tabletPhotosForVertical?.en?.[0]
      ) {
        isValid = false;
        setError(
          `tabletPhotosForVertical.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.tabletPhotoForVertical.message,
          },
          { shouldFocus: true },
        );
      }
      if (
        (!exsitsHorizontalImages ||
          exsitsVerticalImages ||
          (displayVerticalForMyWalletEmpty &&
            [BannerType.HOME_PAGE.value, BannerType.MY_WALLET.value].includes(
              bannerType,
            ))) &&
        !mobilePhotosForVertical?.en?.[0]
      ) {
        isValid = false;
        setError(
          `mobilePhotosForVertical.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.mobilePhotoForVertical.message,
          },
          { shouldFocus: true },
        );
      }
    } else {
      if (
        publishLocation?.includes(BannerLocation.HOME_PAGE) &&
        !coverPhotos?.en?.[0]
      ) {
        isValid = false;
        setError(
          `coverPhotos.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.bannerImage.message,
          },
          { shouldFocus: true },
        );
      }
      if (!mobilePhotos?.en?.[0]) {
        isValid = false;
        setError(
          `mobilePhotos.en.0`,
          {
            type: 'require',
            message: BannerErrorFields.mobilePhotoForVertical.message,
          },
          { shouldFocus: true },
        );
      }
    }

    const checkUrlIsValid = languages
      ?.filter((item) => item.code === LanguageConfig.english)
      .forEach((item) => {
        if (
          landingPageType === LandingPageType.MANUAL_INPUT_URL.value &&
          publishLocation?.includes(BannerLocation.HOME_PAGE) &&
          !manualyUrlIsValid(manualInputUrl[item.code])
        ) {
          setError(
            `manualInputUrl.${item.code}`,
            {
              type: 'require',
              message: BannerErrorFields.manualyUrl.message,
            },
            { shouldFocus: true },
          );
          isValid = false;
        }

        if (
          landingPageType === LandingPageType.MANUAL_INPUT_URL.value &&
          publishLocation?.includes(BannerLocation.WECHAT)
        ) {
          // if (!mpLink || !mpLink?.[item.code]) {
          //   isValid = false;
          //   setError(
          //     `mpLink.${item.code}`,
          //     {
          //       type: 'require',
          //       message: BannerErrorFields.wechatUrl.message,
          //     },
          //     { shouldFocus: true },
          //   );
          // }
          console.log('@@107: ', mpLink, wechatUrl[item.code]);
          if (
            (!mpLink || mpLink?.[item.code] === MPLinkType.URL) &&
            !manualyUrlIsValid(wechatUrl[item.code])
          ) {
            setError(
              `wechatUrl.${item.code}`,
              {
                type: 'require',
                message: BannerErrorFields.wechatUrl.message,
              },
              { shouldFocus: true },
            );
            isValid = false;
          }

          if (mpLink && mpLink[item.code] === MPLinkType.MP_LINK) {
            if (!linkToMPAppId[item.code]) {
              isValid = false;
              setError(
                `linkToMPAppId.${item.code}`,
                {
                  type: 'require',
                  message: BannerErrorFields.linkToMPAppId.message,
                },
                { shouldFocus: true },
              );
            }

            if (!linkToMPPath[item.code]) {
              isValid = false;
              setError(
                `linkToMPPath.${item.code}`,
                {
                  type: 'require',
                  message: BannerErrorFields.linkToMPPath.message,
                },
                { shouldFocus: true },
              );
            }
          }
        }
      });

    // if (!bannerType) {
    //   isValid = false;
    // }

    console.log('@@78: ', publishLocation, bannerType);
    if (!publishLocation || publishLocation?.length <= 0) {
      console.log('@@97');
      isValid = false;
      setError(
        `publishLocation`,
        {
          type: 'require',
          message: BannerErrorFields.publishLocation.message,
        },
        { shouldFocus: true },
      );
    }

    if (
      !targetCustomers ||
      targetCustomers?.length <= 0 ||
      ([
        BannerType.MY_WALLET.value,
        BannerType.GOODIE_BAG_PROMPT.value,
      ].includes(bannerType) &&
        !targetCustomers.includes(TargetCustomerType.LOGGED_IN))
    ) {
      console.log('@@204');
      isValid = false;
      setError(
        `targetCustomers`,
        {
          type: 'require',
          message: BannerErrorFields.targetCustomers.message,
        },
        { shouldFocus: true },
      );
    }

    if (
      targetCustomers?.includes(TargetCustomerType.LOGGED_IN) &&
      selectCountryCodeType === SelectCountryCodeType.SPECIFIC
    ) {
      if (!countryCodes || countryCodes?.length <= 0) {
        console.log('@@225');
        isValid = false;
        setError(
          `countryCodes`,
          {
            type: 'require',
            message: BannerErrorFields.countryCodes.message,
          },
          { shouldFocus: true },
        );
      }
    }

    if (
      targetCustomers?.includes(TargetCustomerType.LOGGED_IN) &&
      targetReward === TargetRewardType.GOODIE_BAG
    ) {
      if (!goodieBags || goodieBags?.length <= 0) {
        isValid = false;
        setError(
          `goodieBags`,
          {
            type: 'require',
            message: BannerErrorFields.goodieBags.message,
          },
          { shouldFocus: true },
        );
      }
    } else if (
      targetCustomers?.includes(TargetCustomerType.LOGGED_IN) &&
      targetReward === TargetRewardType.CAMPAIGN
    ) {
      if (!attributeTags || attributeTags?.length <= 0) {
        isValid = false;
        setError(
          `attributeTags`,
          {
            type: 'require',
            message: BannerErrorFields.attributeTags.message,
          },
          { shouldFocus: true },
        );
      }
    }
    if (
      targetCustomers?.includes(TargetCustomerType.LOGGED_IN) &&
      targetCustomers?.includes(TargetCustomerType.GUEST) &&
      targetReward
    ) {
      isValid = false;
      setError(
        `targetCustomers`,
        {
          type: 'require',
          message: 'Please uncheck “Guest” in Target customers.',
        },
        { shouldFocus: true },
      );
    }

    console.log('@@133: ', isValid);
    if (isValid) {
      dispatch(
        createAction('bannerList/createOrUpdateBanner')({
          data: {
            ...getValues(),
            isPublished: !!isPublished,
          },
          isCreate: params.id ? false : true,
        }),
      );
      return;
    }
  };

  return (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={'Save only'}
        saveText={`Save and ${banner.isPublished ? 'UnPublish' : 'Publish'}`}
        saveTempAction={async () => save(banner.isPublished)}
        saveAction={async () => save(!banner.isPublished)}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  banner: state.bannerList.banner,
  languages: state.language.allList,
  countryCodeList: state.countryCode.notPagedAllList,
  goodieBagList: state.goodieBags.notPagedAllList,
  attributeTagList: state.attributeTag.notPagedAllList,
});

export default connect(mapPropsToState)(CreateBannerStepOne);
